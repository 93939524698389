import {
  BarSvgProps,
  ResponsiveBar,
  type BarCommonProps,
  type BarDatum,
  type LegendLabelDatum,
} from "@nivo/bar";
import useNivoTheme from "../../../theme/nivo";
import BarChartTooltip from "./BarChartTooltip";
import CustomBarComponent from "./CustomBar";

interface IndexBarChartProps {
  data?: BarDatum[];
  keys: BarCommonProps<unknown>["keys"];
  minValue?: number;
  maxValue?: number;
  symbol?: string;
  groupMode?: BarCommonProps<unknown>["groupMode"];
  indexBy?: BarCommonProps<unknown>["indexBy"];
  transparentBg?: boolean;
  customLabelFn?: (item: LegendLabelDatum<BarDatum>) => string;
  indexFormatter?: BarSvgProps<BarDatum>["axisBottom"]["format"];
  valueFormatter?: BarSvgProps<BarDatum>["axisLeft"]["format"];
  barMaxWidth?: number;
}

// make sure parent container have a defined height when using responsive component, otherwise
// height will be 0 and no chart will be rendered.
const MyResponsiveBar: React.FC<IndexBarChartProps> = ({
  data,
  keys,
  indexBy,
  minValue,
  maxValue,
  symbol,
  groupMode,
  transparentBg = false,
  customLabelFn,
  indexFormatter,
  valueFormatter,
  barMaxWidth,
}) => {
  const nivoTheme = useNivoTheme();

  if (!data || !data.length) {
    return null;
  }

  // Add an offset to the value to make sure it is visible on the chart.
  const yAxisTicksOffsetPercentage = 0.05;
  const minYValue =
    minValue !== undefined
      ? minValue + minValue * yAxisTicksOffsetPercentage
      : "auto";
  const maxYValue =
    maxValue !== undefined
      ? maxValue + maxValue * yAxisTicksOffsetPercentage
      : "auto";

  return (
    <ResponsiveBar
      barComponent={barMaxWidth ? CustomBarComponent : undefined}
      data={data}
      keys={keys}
      colors={["#bc5090", "#ffa600"]}
      margin={{ top: 50, right: 30, bottom: 30, left: 50 }}
      axisLeft={{
        format: (d) =>
          `${symbol ?? ""} ${new Intl.NumberFormat(undefined, {
            notation: "compact",
            compactDisplay: "short",
          }).format(d as number)}`,
      }}
      valueScale={{ type: "linear" }}
      axisBottom={{
        format: indexFormatter ?? ((d) => d as string),
      }}
      indexScale={{ type: "band", round: true }}
      valueFormat={(value) => `${symbol ?? ""} ${value}`}
      axisTop={null}
      axisRight={null}
      minValue={minYValue}
      maxValue={maxYValue}
      // axisLeft={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: "food",
      //   legendPosition: "middle",
      //   legendOffset: -40,
      // }}
      // axisBottom={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: "calendarYear",
      //   legendPosition: "middle",
      //   legendOffset: 32,
      // }}
      animate
      theme={{
        ...nivoTheme,
        ...(transparentBg && { background: "transparent" }),
      }}
      groupMode={groupMode}
      indexBy={indexBy}
      enableLabel={false}
      legendLabel={customLabelFn}
      legends={[
        {
          dataFrom: "keys",
          anchor: "top-right",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: -20,
          itemsSpacing: 0,
          itemWidth: 75,
          itemHeight: 10,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 16,
        },
      ]}
      tooltip={(props) => <BarChartTooltip {...props} />}
    />
  );
};

export default MyResponsiveBar;
