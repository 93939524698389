import { Box, Flex, Text } from "@chakra-ui/react";
import { BarDatum, BarTooltipProps } from "@nivo/bar";
import { format } from "date-fns";
import isValidDate from "../../../lib/utils/isValidDate";

const BarChartTooltip: React.FC<BarTooltipProps<BarDatum>> = ({
  indexValue,
  color,
  formattedValue,
}) => {
  return (
    <Flex
      p={2}
      bgColor="slate.100"
      _dark={{
        bgColor: "slate.700",
      }}
      flexDirection="column"
      gap={2}
    >
      <Text
        as="span"
        textColor="slate.500"
        _dark={{
          textColor: "slate.300",
        }}
        fontSize="sm"
      >
        {isValidDate(indexValue)
          ? format(indexValue as unknown as Date, "LLL dd")
          : indexValue}
      </Text>
      <Flex gap={2}>
        <Box width="4px" borderRadius="md" bgColor={color}></Box>
        <Text as="span" fontSize="sm">
          {formattedValue}
        </Text>
      </Flex>
    </Flex>
  );
};

export default BarChartTooltip;
