import { Box, Flex, Heading } from "@chakra-ui/react";

interface CardProps {
  title?: string;
  subtitle?: string;
  action?: string | React.ReactNode;
  children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ title, subtitle, action, children }) => {
  return (
    <Box
      h="100%"
      w="100%"
      p={{ base: 0, md: 4 }}
      mb={{ base: 8, md: 4 }}
      boxShadow="md"
    >
      {title || action ? (
        <Flex alignItems="center" justifyContent="space-between" mb={4}>
          <Flex flexDirection="column" gap={2}>
            {title && (
              <Heading as="h2" fontWeight="bold" fontSize="1.5rem">
                {title}
              </Heading>
            )}
            {subtitle && (
              <Heading as="h4" fontWeight="bold" fontSize="1rem">
                {subtitle}
              </Heading>
            )}
          </Flex>
          {action && <Box>{action}</Box>}
        </Flex>
      ) : null}
      <Box w="100%" h="calc(100% - 2rem)" overflowX="auto">
        {children}
      </Box>
    </Box>
  );
};

export default Card;
