import type { Category } from "../api/category/useCategories";

export const getParentCategory = (
  allCategories: Category[],
  categoryKeyStr: string
): Category | undefined => {
  const categoryKey = Number.parseInt(categoryKeyStr, 10);
  const category = allCategories.find((category) =>
    category.childCategory.find((c) => c.categoryKey === categoryKey)
  );
  return category;
};

export const getSiblingCategories = (
  allCategories: Category[],
  categoryKeyStr: string
) => {
  const categoryKey = Number.parseInt(categoryKeyStr, 10);
  const parentCategory = getParentCategory(allCategories, categoryKeyStr);
  if (!parentCategory) {
    return [];
  }
  return parentCategory.childCategory.filter(
    (c) => c.categoryKey !== categoryKey
  );
};

export const addParentCategory = (
  allCategories: Category[],
  currentCategories: string[],
  newCategoryStr: string
): string[] => {
  const parentCategory = getParentCategory(allCategories, newCategoryStr);
  if (parentCategory) {
    const alreadyHasParent = currentCategories.find(
      (c) => c === parentCategory.categoryKey.toString()
    );
    if (!alreadyHasParent) {
      return [...currentCategories, parentCategory.categoryKey.toString()];
    }
  }
  return currentCategories;
};

export const removeParentCategory = (
  allCategories: Category[],
  currentCategories: string[],
  removedCategoryStr: string
): string[] => {
  const parentCategory = getParentCategory(allCategories, removedCategoryStr);
  if (parentCategory) {
    const siblingCategoryKeys = getSiblingCategories(
      allCategories,
      removedCategoryStr
    ).map((s) => s.categoryKey.toString());
    const hasSiblingCategories = currentCategories.some((c) =>
      siblingCategoryKeys.includes(c)
    );
    if (!hasSiblingCategories) {
      return currentCategories.filter(
        (categoryKey) => categoryKey !== parentCategory.categoryKey.toString()
      );
    }
  }
  return currentCategories;
};

export const getAllCategoryKeys = (categories: Category[]): string[] => {
  const categoryKeys: string[] = [];
  for (const category of categories) {
    categoryKeys.push(category.categoryKey.toString());
    for (const childCategory of category.childCategory) {
      categoryKeys.push(childCategory.categoryKey.toString());
    }
  }
  return categoryKeys;
};

export const getAllCategories = (
  categories: Category[]
): { key: Category["categoryKey"]; name: Category["name"] }[] => {
  const _categories: {
    key: Category["categoryKey"];
    name: Category["name"];
  }[] = [];
  _categories.push({
    key: 0,
    name: "Uncategorized",
  });
  for (const category of categories) {
    _categories.push({ key: category.categoryKey, name: category.name });
    for (const childCategory of category.childCategory) {
      _categories.push({
        key: childCategory.categoryKey,
        name: childCategory.name,
      });
    }
  }
  return _categories;
};

export const getCategoryTranslationKey = (category: Category["name"]) => {
  return `categories:${category
    .replaceAll(", ", "-")
    .replaceAll(" ", "-")
    .replaceAll("&", "and")
    .toLowerCase()}`;
};
