import { Box, Flex, Progress, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import type { CategoriesSpending } from "../lib/api/account/useTransactionsCategorySpending";
import { getCategoryTranslationKey } from "../lib/utils/categories";
import { CurrencyCodeToSymbol } from "../lib/utils/currencySymbol";
import CategoryIcon from "./ui/CategoryIcon";

const Styles = styled.div`
  table {
    width: 100%;
    border-spacing: 0;

    th,
    td {
      margin: 0;
      padding: 0.5rem 1rem;
    }

    thead > tr {
      border-bottom: 1px solid #dfe0eb;
    }

    thead > tr > th {
      font-size: 0.8rem;
      letter-spacing: 0.3px;
    }

    thead > tr > th:last-of-type > span:first-of-type {
      margin-left: auto;
    }

    tbody > tr > td {
      font-size: 0.9rem;
      padding: 1rem;
      &:last-of-type {
        text-align: right;
      }
    }
  }
`;

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: "distribution",
              desc: false,
            },
          ],
        },
      },
      useSortBy
    );

  return (
    <Box as="table" {...getTableProps()}>
      <Box as="thead">
        {headerGroups.map((headerGroup, i) => (
          <Box as="tr" key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <Box
                as="th"
                key={j}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <Text as="span">{column.render("Header")}</Text>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box as="tbody" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Box as="tr" key={i} {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                return (
                  <Box as="td" key={j} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

interface CategoriesSpendingTableProps {
  categoriesSpending: CategoriesSpending[];
}

const CategoriesSpendingTable: React.FC<CategoriesSpendingTableProps> = ({
  categoriesSpending,
}) => {
  const { t } = useTranslation(["common", "categories"]);

  const totalAmount = useMemo(
    () => categoriesSpending.reduce((acc, curr) => acc + curr.amount, 0),
    [categoriesSpending]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "name",
        Cell: ({ value }) => (
          <Flex alignItems="center" gap={2}>
            <CategoryIcon category={value} />
            <Text as="span" fontWeight="semibold">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {t(getCategoryTranslationKey(value as string))}
            </Text>
          </Flex>
        ),
      },
      {
        Header: t("num-transactions"),
        accessor: "numTransactions",
        Cell: ({ value }) => <Text as="span">{value}</Text>,
      },
      {
        Header: t("distribution"),
        id: "distribution",
        Cell: ({ row }) => {
          const progress = (row.original.amount / totalAmount) * 100;
          return (
            <Flex gap={2} alignItems="center">
              <Progress
                value={progress}
                height={2}
                width="100%"
                borderRadius={4}
                colorScheme="brand"
                isAnimated
              />
              <Text as="span" minWidth="3.5rem" whiteSpace="nowrap">
                {progress.toFixed(1)}%
              </Text>
            </Flex>
          );
        },
      },
      {
        Header: t("amount"),
        accessor: "amount",
        Cell: ({ value, row }) => (
          <Text as="span">
            {CurrencyCodeToSymbol[row.original.currency]}{" "}
            {(value as number).toFixed(2)}
          </Text>
        ),
      },
    ],
    [t, totalAmount]
  );

  return (
    <Styles>
      <Table columns={columns} data={categoriesSpending} />
    </Styles>
  );
};

export default CategoriesSpendingTable;
