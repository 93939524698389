import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import api from "../api";

export interface Portfolio {
  name: string;
  balance: number;
  currencyKey: { code: string };
  portfolioKey: number;
}

export interface GetPortfoliosResponse {
  portfolios: Portfolio[];
}

const getPortfolios = async () => {
  const { data } = await api.get<GetPortfoliosResponse>(`/v1/portfolio`);
  return data;
};

export default function usePortfolios<R = GetPortfoliosResponse>(
  options?: UseQueryOptions<GetPortfoliosResponse, unknown, R>
) {
  return useQuery(["portfolios"], () => getPortfolios(), options);
}
