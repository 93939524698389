import { useColorMode, useTheme } from "@chakra-ui/react";
import { Theme } from "@nivo/core";
import { useMemo } from "react";
import { Theme as CustomTheme } from "./index";

const defaultTheme = {
  background: "#ffffff",
  textColor: "#333333",
  fontSize: 11,
  axis: {
    domain: {
      line: {
        stroke: "#777777",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#333333",
      },
    },
    ticks: {
      line: {
        stroke: "#777777",
        strokeWidth: 1,
      },
      text: {
        fontSize: 11,
        fill: "#333333",
      },
    },
  },
  grid: {
    line: {
      stroke: "#dddddd",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: "#333333",
      },
    },
    text: {
      fontSize: 11,
      fill: "#333333",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: "#333333",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#333333",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: "#ffffff",
      color: "#333333",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

interface NivoThemeProps {
  transparentBackground?: boolean;
}

const useNivoTheme = ({
  transparentBackground,
}: NivoThemeProps = {}): Theme => {
  const { colorMode } = useColorMode();
  const theme = useTheme<CustomTheme>();
  const isDark = colorMode === "dark";
  return useMemo(
    () => ({
      ...defaultTheme,
      background: transparentBackground
        ? "transparent"
        : isDark
        ? theme.colors.gray[800]
        : theme.colors.gray[50],
      textColor: isDark ? theme.colors.gray[200] : theme.colors.gray[800],
      fontSize: 12,
      axis: {
        // domain: {
        //   line: {
        //     stroke: "#777777",
        //     strokeWidth: 1,
        //   },
        // },
        // legend: {
        //   text: {
        //     fontSize: 12,
        //     fill: "#333333",
        //   },
        // },
        ticks: {
          line: {
            stroke: isDark ? theme.colors.gray[200] : theme.colors.gray[800],
            strokeWidth: 1,
            opacity: 0.3,
          },
          text: {
            fontSize: 11,
            fill: isDark ? theme.colors.gray[200] : theme.colors.gray[800],
          },
        },
      },
      grid: {
        line: {
          stroke: isDark ? theme.colors.gray[200] : theme.colors.gray[800],
          strokeWidth: 1,
          opacity: 0.05,
        },
      },
      legends: {
        title: {
          text: {
            fontSize: 11,
            fill: isDark ? theme.colors.gray[200] : theme.colors.gray[800],
          },
        },
        text: {
          fontSize: 12,
          fill: isDark ? theme.colors.gray[100] : theme.colors.gray[800],
        },
        ticks: {
          line: {},
          text: {
            fontSize: 10,
            fill: isDark ? theme.colors.gray[200] : theme.colors.gray[800],
          },
        },
      },
      // annotations: {
      //   text: {
      //     fontSize: 13,
      //     fill: "#333333",
      //     outlineWidth: 2,
      //     outlineColor: "#ffffff",
      //     outlineOpacity: 1,
      //   },
      //   link: {
      //     stroke: "#000000",
      //     strokeWidth: 1,
      //     outlineWidth: 2,
      //     outlineColor: "#ffffff",
      //     outlineOpacity: 1,
      //   },
      //   outline: {
      //     stroke: "#000000",
      //     strokeWidth: 2,
      //     outlineWidth: 2,
      //     outlineColor: "#ffffff",
      //     outlineOpacity: 1,
      //   },
      //   symbol: {
      //     fill: "#000000",
      //     outlineWidth: 2,
      //     outlineColor: "#ffffff",
      //     outlineOpacity: 1,
      //   },
      // },
      // tooltip: {
      //   container: {
      //     background: "#ffffff",
      //     color: "#333333",
      //     fontSize: 12,
      //   },
      //   basic: {},
      //   chip: {},
      //   table: {},
      //   tableCell: {},
      //   tableCellValue: {},
      // },
    }),
    [isDark, theme.colors.gray]
  );
};

export default useNivoTheme;
