import { BarDatum, BarItem, BarItemProps } from "@nivo/bar";
import { useSpring } from "@react-spring/web";
import { useEffect } from "react";

const BAR_MAX_WIDTH = 20;

const CustomBarComponent: React.FC<BarItemProps<BarDatum>> = (props) => {
  const {
    bar: { data, ...bar },
    style,
  } = props;

  const w = Math.min(bar.width, BAR_MAX_WIDTH);
  const x = bar.x + bar.width / 2 - w / 2;

  const [spring, setSpring] = useSpring(() => ({
    width: w,
    transform: `translate(${x}, ${bar.y})`,
  }));

  useEffect(() => {
    setSpring({ width: w, transform: `translate(${x}, ${bar.y})` });
  }, [w, setSpring, x, bar]);

  return (
    <BarItem
      {...props}
      bar={{ ...bar, data, x, width: w }}
      style={{ ...style, ...spring }}
    />
  );
};

export default CustomBarComponent;
