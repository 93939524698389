import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useTheme } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { useExpanded, useSortBy, useTable } from "react-table";
import type { Account } from "../lib/api/account/useAccounts";
import { Transaction } from "../lib/api/account/useTransactions";
import type { Portfolio } from "../lib/api/portfolio/usePortfolios";
import { CurrencyCodeToSymbol } from "../lib/utils/currencySymbol";
import AccountTransactionsLineChartMini from "./AccountTransactionsLineChartMini";

const Styles = styled.div`
  table {
    width: 100%;
    border-spacing: 0;

    th,
    td {
      margin: 0;
      padding: 0.5rem 1rem;
    }

    thead > tr > th {
      border-bottom: 1px solid #dfe0eb;
      color: #9fa2b4;
      font-size: 0.8rem;
      letter-spacing: 0.3px;
      text-align: left;
      &:nth-of-type(3) > div > span:first-of-type,
      &:last-of-type > div > span:first-of-type {
        margin-left: auto;
      }
      &:first-of-type {
        width: 50px;
      }
      &:last-of-type {
        width: 200px;
      }
    }

    tbody > tr > td {
      font-size: 0.9rem;
      padding: 1rem;
      &:nth-of-type(3) {
        padding: 0;
      }
      &:nth-of-type(3),
      &:last-of-type {
        text-align: right;
      }
    }
    svg {
      color: #c5c7cd;
      &:not(:last-of-type) {
        margin-right: 0.4rem;
      }
    }
  }
`;

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          expanded: data.map((_, i) => ({ [i]: true })),
          sortBy: [
            {
              id: "name",
              desc: false,
            },
          ],
        },
      },
      useSortBy,
      useExpanded
    );
  const theme = useTheme();

  return (
    <Box as="table" {...getTableProps()}>
      <Box as="thead">
        {headerGroups.map((headerGroup, i) => (
          <Box as="tr" key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <Box
                as="th"
                key={j}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <Flex gap={1}>
                  <Text as="span">{column.render("Header")}</Text>
                  <Box as="span">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon />
                      ) : (
                        <ChevronUpIcon />
                      )
                    ) : (
                      ""
                    )}
                  </Box>
                </Flex>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box as="tbody" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Box
              as="tr"
              key={i}
              {...row.getRowProps()}
              backgroundColor={
                row.depth === 0 ? theme.colors.gray[100] : "initial"
              }
              _dark={{
                backgroundColor:
                  row.depth === 0 ? theme.colors.gray[700] : "initial",
              }}
            >
              {row.cells.map((cell, j) => {
                return (
                  <Box as="td" key={j} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

interface DashboardNetworthTableProps {
  accounts: Account[];
  accountsTransactions: Transaction[];
  portfolios: Portfolio[];
}

const DashboardNetworthTable: React.FC<DashboardNetworthTableProps> = ({
  accounts,
  accountsTransactions,
  portfolios,
}) => {
  const { t } = useTranslation("common");

  const data = useMemo(() => {
    if (
      (!accounts || accounts.length === 0) &&
      (!portfolios || portfolios.length === 0)
    ) {
      return [];
    }
    return [
      {
        name: t("accounts"),
        balance: accounts.reduce(
          (acc, account) => acc + account.appuserCurrencyBalance,
          0
        ),
        currencyCode: accounts[0]?.accountOwnerAppuserKey.currencyKey.code,
        subRows: accounts.map((account) => ({
          accountKey: account.accountKey,
          name: account.accountName,
          balance: account.balance,
          currencyCode: account.currencyKey.code,
          appuserCurrencyBalance: account.appuserCurrencyBalance,
          appuserCurrencyCode: account.accountOwnerAppuserKey.currencyKey.code,
        })),
      },
      {
        name: t("portfolios"),
        balance: portfolios.reduce(
          (acc, portfolio) => acc + portfolio.balance,
          0
        ),
        currencyCode: portfolios?.[0]?.currencyKey.code, // TODO: This should be the user's WM account currency
        subRows: portfolios.map((portfolio) => ({
          name: portfolio.name,
          balance: portfolio.balance,
          currencyCode: portfolio.currencyKey.code,
          // FIXME: the stuff below is not implemented yet
          appuserCurrencyBalance: portfolio.balance,
          appuserCurrencyCode: portfolio.currencyKey.code,
        })),
      },
    ];
  }, [accounts, portfolios, t]);

  const columns = useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </span>
          ) : null,
        maxWidth: "1rem",
      },
      {
        Header: t("name"),
        accessor: "name",
        Cell: ({ value, row }) => {
          const isRootRow = row.depth === 0;
          return (
            <Text as="span" fontWeight={isRootRow ? "bold" : "normal"}>
              {value}
            </Text>
          );
        },
      },
      {
        id: "balance-chart",
        Cell: ({ row }) => {
          const isRootRow = row.depth === 0;
          if (!(row.id as string).startsWith("0")) {
            return;
          }
          const transactions = isRootRow
            ? accountsTransactions
            : accountsTransactions.filter(
                (t) => t.accountKey.accountKey === row.original.accountKey
              );
          return transactions.length ? (
            <Box marginLeft="auto" width="fit-content">
              <AccountTransactionsLineChartMini transactions={transactions} />
            </Box>
          ) : null;
        },
      },
      {
        Header: t("balance"),
        accessor: "appuserCurrencyBalance",
        Cell: ({ value, row }) => {
          const isRootRow = row.depth === 0;
          return (
            <Text
              as="span"
              fontWeight={isRootRow ? "bold" : "normal"}
              letterSpacing="wide"
            >
              {!isRootRow &&
              row.original.appuserCurrencyCode !== row.original.currencyCode ? (
                <Text
                  as="span"
                  fontWeight="normal"
                  fontSize="xs"
                  color="gray.800"
                  _dark={{ color: "gray.300" }}
                  mr={2}
                >
                  ({CurrencyCodeToSymbol[row.original.currencyCode as string]}{" "}
                  {(row.original.balance as number).toFixed(2)})
                </Text>
              ) : null}
              {isRootRow
                ? `${
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    row.original.currencyCode
                      ? CurrencyCodeToSymbol[
                          row.original.currencyCode as string
                        ]
                      : ""
                  } ${
                    row.original.currencyCode
                      ? (row.original.balance as number).toFixed(2)
                      : "N/A"
                  }`
                : `${
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    CurrencyCodeToSymbol[row.original.appuserCurrencyCode]
                  } ${value ? (value as number).toFixed(2) : "0.00"}`}
            </Text>
          );
        },
      },
    ],
    [accountsTransactions, t]
  );

  return (
    <>
      <Styles>
        <Table columns={columns} data={data} />
      </Styles>
    </>
  );
};

export default DashboardNetworthTable;
