import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import api from "../api";

interface CategorySpending {
  categoryKey: number;
  amount: number;
  currency?: string;
  icon?: string | undefined;
  bgColor: string;
  textColor: string;
  isSystemCategory: boolean;
  numTransactions: number;
  name: string;
}

export interface CategoriesSpending extends CategorySpending {
  childCategories: CategorySpending[];
}

export interface GetTransactionsCategorySpendingResponse {
  categorySpending: CategoriesSpending[];
}

interface GetTransactionsCategorySpendingRequest {
  fromDate?: Date;
  toDate?: Date;
  limit?: number;
}

const getTransactionsCategorySpending = async (
  params: GetTransactionsCategorySpendingRequest = {}
) => {
  const { data } = await api.get<GetTransactionsCategorySpendingResponse>(
    "/v1/account/transaction/category-spending",
    {
      params,
    }
  );
  return data;
};

export default function useTransactionsCategorySpending<
  R = GetTransactionsCategorySpendingResponse
>(
  params?: GetTransactionsCategorySpendingRequest,
  options?: UseQueryOptions<GetTransactionsCategorySpendingResponse, unknown, R>
) {
  return useQuery(
    ["transactions-category-spending", params],
    () => getTransactionsCategorySpending(params),
    options
  );
}
