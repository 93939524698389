import { Box } from "@chakra-ui/react";
import type { Serie } from "@nivo/line";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import type { Transaction } from "../lib/api/account/useTransactions";
import { CurrencyCodeToSymbol } from "../lib/utils/currencySymbol";
import LineChartMini from "./ui/Charts/LineChartMini";

interface AccountTransactionsLineChartMiniProps {
  transactions: Transaction[];
}

const AccountTransactionsLineChartMini: React.FC<
  AccountTransactionsLineChartMiniProps
> = ({ transactions }) => {
  const ascTransactions = transactions
    .slice()
    .reverse()
    .map((transaction) => ({
      ...transaction,
      transactionDate: new Date(transaction.transactionDate),
    }));
  const uniqueTransactionDates = [
    ...new Set(
      ascTransactions.map((transaction) =>
        endOfDay(transaction.transactionDate).getTime()
      )
    ),
  ];
  const accountKeys = ascTransactions.map(
    (transaction) => transaction.accountKey.accountKey
  );
  const uniqueAccountKeys = [...new Set(accountKeys)];

  const transactionsData = uniqueTransactionDates.map((transactionDate, i) => {
    const transactionDateFormatted = new Date(transactionDate);
    const balanceAtDate =
      i === 0
        ? ascTransactions[0].appuserCurrencyBalance
        : uniqueAccountKeys.reduce((acc, accountKey) => {
            const transactionsAtDateForAccount = transactions.filter(
              (transaction) =>
                transaction.accountKey.accountKey === accountKey &&
                !isAfter(
                  new Date(transaction.transactionDate),
                  transactionDateFormatted
                )
            );
            if (transactionsAtDateForAccount.length > 0) {
              const lastTransactionAtDateForAccount =
                transactionsAtDateForAccount[0];
              return (
                acc + lastTransactionAtDateForAccount.appuserCurrencyBalance
              );
            }
            return acc;
          }, 0);
    return {
      x: transactionDateFormatted,
      y: balanceAtDate.toFixed(2),
    };
  });

  // If we dont have a transaction for today, add a fake one so the chart looks better
  if (
    transactionsData[transactionsData.length - 1].x.getTime() !==
    endOfDay(new Date()).getTime()
  ) {
    transactionsData.push({
      x: endOfDay(new Date()),
      y: transactionsData[transactionsData.length - 1].y,
    });
  }

  const data: Serie[] = [
    {
      id: "transactions",
      data: transactionsData,
    },
  ];

  return (
    <Box height="50px" width="100px">
      <LineChartMini
        data={data}
        symbol={CurrencyCodeToSymbol[transactions[0].appuserCurrencyKey.code]}
      />
    </Box>
  );
};

export default AccountTransactionsLineChartMini;
