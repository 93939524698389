import {
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import type { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import NextLink from "next/link";
import { useMemo } from "react";
import AccountTransactionsSpendingType from "../components/AccountTransactionsSpendingType";
import CategoriesSpendingTable from "../components/CategoriesSpendingTable";
import DashboardNetworthTable from "../components/DashboardNetworthTable";
import Welcome from "../components/layout/Welcome";
import Card from "../components/ui/Card";
import useAccounts from "../lib/api/account/useAccounts";
import useTransactions from "../lib/api/account/useTransactions";
import useTransactionsCategorySpending from "../lib/api/account/useTransactionsCategorySpending";
import usePortfolios from "../lib/api/portfolio/usePortfolios";

const Home: NextPage = () => {
  const { t } = useTranslation(["common", "navigation"]);
  const title = `${t("navigation:dashboard")} - WonderMoney`;

  const { data: transactionsData, isLoading: isLoadingTransactionsData } =
    useTransactions({
      includeEmptyAccounts: true,
    });

  const unreviewdTransactionsData = useMemo(
    () =>
      transactionsData
        ? transactionsData.transactions.filter((t) => t.reviewed === false)
        : [],
    [transactionsData]
  );

  const { data: accountsData, isLoading: isLoadingAccountsData } =
    useAccounts();

  const { data: portfoliosData, isLoading: isLoadingPortfoliosData } =
    usePortfolios();

  const isLoading =
    isLoadingAccountsData ||
    isLoadingPortfoliosData ||
    isLoadingTransactionsData;
  const isEmpty =
    !accountsData?.accounts.length && !portfoliosData?.portfolios.length;

  const {
    data: categoriesSpendingData,
    isLoading: isLoadingCategoriesSpendingData,
  } = useTransactionsCategorySpending({ limit: 5 });

  const renderUnreviewedTransactionsAlert = () => {
    return (
      !isLoadingTransactionsData &&
      !!unreviewdTransactionsData?.length && (
        <Alert status="info" my={4}>
          <AlertIcon />
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            gap={{ base: 0, md: 2 }}
          >
            <Text>
              {t("unreviewed-transactions-count", {
                count: unreviewdTransactionsData.length,
              })}
            </Text>
            <NextLink
              href="/accounts/transactions/review"
              legacyBehavior
              passHref
            >
              <Link textDecoration="underline" textUnderlineOffset={2}>
                {t("unreviewed-transactions-count-click")}
              </Link>
            </NextLink>
          </Flex>
        </Alert>
      )
    );
  };

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {isLoading ? (
        <Spinner />
      ) : isEmpty ? (
        <Welcome />
      ) : (
        <>
          <Heading as="h1">{t("at-glance")}</Heading>
          <Text color="gray.800" _dark={{ color: "gray.300" }}>
            {t("summary-metrics-month")}
          </Text>
          {renderUnreviewedTransactionsAlert()}
          <Card title={t("net-worth")}>
            <DashboardNetworthTable
              accounts={accountsData.accounts}
              accountsTransactions={transactionsData.transactions}
              portfolios={portfoliosData.portfolios}
            />
          </Card>
          <Card
            title={t("top-categories")}
            // action={
            //   <Button variant="link" fontSize="sm">
            //     {t("view-all")}{" "}
            //     <Icon as={BsArrowUpRight} strokeWidth={1} ml={1} />
            //   </Button>
            // }
          >
            {isLoadingCategoriesSpendingData ? (
              <Spinner />
            ) : categoriesSpendingData.categorySpending.length > 0 ? (
              <CategoriesSpendingTable
                categoriesSpending={categoriesSpendingData.categorySpending}
              />
            ) : (
              <Flex w="100%" justifyContent="center" alignItems="center" h={10}>
                <Text color="slate.400" fontSize="sm">
                  {t("empty-transactions-last-week")}
                </Text>
              </Flex>
            )}
          </Card>
          <Card title={t("last-week-transactions")}>
            <AccountTransactionsSpendingType />
          </Card>
        </>
      )}
    </>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        "common",
        "navigation",
        "categories",
      ])),
    },
  };
}

export default Home;
