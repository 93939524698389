import { Button, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import useOnboardingStore from "../../store/onboarding/onboarding";
import Onboarding from "../Onboarding";

const Welcome: React.FC = () => {
  const { t } = useTranslation("common");
  const { setEnabled } = useOnboardingStore();

  return (
    <SimpleGrid columns={2} columnGap="4rem" width="100%" mt="10rem">
      <Flex position="relative">
        <Image
          src="/undraw_welcome.svg"
          alt={t("welcome")}
          fill
          style={{
            maxWidth: "400px",
            margin: "0 auto",
          }}
          sizes="400px"
        />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" gap={16}>
        <Flex flexDirection="column" justifyContent="center" gap={6}>
          <Heading as="h1">{t("welcome")}</Heading>
          <Text>{t("welcome-description")}</Text>
        </Flex>
        <Button maxWidth="300px" onClick={() => setEnabled(true)}>
          {t("lets-start")}
        </Button>
      </Flex>
      <Onboarding />
    </SimpleGrid>
  );
};

export default Welcome;
