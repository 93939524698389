export enum CurrencyCodeToSymbol {
  USD = "$",
  EUR = "€",
  GBP = "£",
  JPY = "¥",
  CAD = "$",
  CHF = "CHF",
  HUF = "Ft",
  CZK = "Kč",
}
