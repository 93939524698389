import { Flex, Spinner, Text } from "@chakra-ui/react";
import { BarDatum, LegendLabelDatum } from "@nivo/bar";
import { format, startOfDay, subDays } from "date-fns";
import { useTranslation } from "next-i18next";
import useTransactions from "../lib/api/account/useTransactions";
import { CurrencyCodeToSymbol } from "../lib/utils/currencySymbol";
import MyResponsiveBar from "./ui/Charts/BarChart";

const AccountTransactionsSpendingType = () => {
  const { t } = useTranslation("common");
  const dateSevenDaysAgo = startOfDay(subDays(new Date(), 7));
  const { data, isLoading } = useTransactions(
    {
      fromDate: dateSevenDaysAgo,
    },
    {
      select: ({ transactions }) => {
        const transactionsGroupedByDateAndType = transactions.reduce(
          (acc, t) => {
            const date = startOfDay(new Date(t.transactionDate));
            const dateObj = acc.find(
              (d) => d.date.getTime() === date.getTime()
            );
            if (!dateObj) {
              acc.push({
                date: date,
                income:
                  t.appuserSettlementAmount > 0 ? t.appuserSettlementAmount : 0,
                expense:
                  t.appuserSettlementAmount < 0
                    ? Math.abs(t.appuserSettlementAmount)
                    : 0,
                currency: t.appuserCurrencyKey.code,
              });
            } else {
              dateObj.income = Number.parseFloat(
                (
                  dateObj.income +
                  (t.appuserSettlementAmount > 0
                    ? t.appuserSettlementAmount
                    : 0)
                ).toFixed(2)
              );
              dateObj.expense = Number.parseFloat(
                (
                  dateObj.expense +
                  (t.appuserSettlementAmount < 0
                    ? Math.abs(t.appuserSettlementAmount)
                    : 0)
                ).toFixed(2)
              );
            }
            return acc;
          },
          [] as {
            date: Date;
            income: number;
            expense: number;
            currency: string;
          }[]
        );
        return transactionsGroupedByDateAndType;
      },
    }
  );

  const interval = (data ?? []).reduce(
    (interval, t) => {
      return {
        minValue: 0,
        maxValue: Math.max(interval.maxValue, t.income, t.expense),
      };
    },
    { minValue: 0, maxValue: 0 }
  );

  const getLabel = (item: LegendLabelDatum<BarDatum>): string => {
    if (item.id === "income") {
      return t("income");
    }
    if (item.id === "expense") {
      return t("expense");
    }
    return "";
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!isLoading && (!data || data.length === 0)) {
    return (
      <Flex w="100%" justifyContent="center" alignItems="center" height={10}>
        <Text color="slate.400" fontSize="sm">
          {t("empty-transactions-last-week")}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex w="100%" h="400px" justifyContent="center" alignItems="center">
      <MyResponsiveBar
        data={[...data].reverse() as unknown as BarDatum[]}
        groupMode="grouped"
        indexBy="date"
        keys={["income", "expense"]}
        symbol={CurrencyCodeToSymbol[data[0].currency]}
        minValue={interval.minValue}
        maxValue={interval.maxValue}
        transparentBg
        customLabelFn={getLabel}
        indexFormatter={(d: Date) => format(d, "dd MMM")}
        barMaxWidth={20}
      />
    </Flex>
  );
};

export default AccountTransactionsSpendingType;
