import { useColorModeValue } from "@chakra-ui/react";
import { Steps } from "intro.js-react";
import { useTranslation } from "next-i18next";
import useOnboardingStore from "../store/onboarding/onboarding";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const Onboarding: React.FC = () => {
  const { t } = useTranslation("common");
  const { enabled, initialStep, setOpenProgressMenu, setEnabled } =
    useOnboardingStore();
  const onboardingTootlipClass = useColorModeValue(
    "onboarding-tooltip-light",
    "onboarding-tooltip-dark"
  );

  const steps = [
    {
      element: "#menu-button-onboarding-progress",
      intro: t("onboarding-1"),
      tooltipClass: onboardingTootlipClass,
      highlightClass: "onboarding-highlight",
    },
    {
      element: "#menu-list-onboarding-progress",
      intro: t("onboarding-2"),
      tooltipClass: onboardingTootlipClass,
    },
    {
      element: "#menu-list-onboarding-progress > button:first-child",
      intro: t("onboarding-3"),
      tooltipClass: onboardingTootlipClass,
    },
  ];

  const onExit = () => {
    // do something
  };

  const onBeforeChange = async (newStep: number) => {
    if (newStep === 1) {
      setOpenProgressMenu(true);
      await sleep(100);
    }
  };

  const onComplete = () => {
    setEnabled(false);
  };

  return (
    <Steps
      enabled={enabled}
      steps={steps}
      initialStep={initialStep}
      onExit={onExit}
      onBeforeChange={(step) => onBeforeChange(step)}
      onComplete={() => onComplete()}
      options={{
        showStepNumbers: true,
        scrollToElement: true,
        showBullets: false,
        nextLabel: t("next"),
        prevLabel: t("previous"),
        doneLabel: t("done"),
        exitOnOverlayClick: false,
      }}
    />
  );
};

export default Onboarding;
