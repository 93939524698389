import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import api from "../api";

export interface Account {
  accountKey: number;
  accountName: string;
  accountFinancialInstitutionKey: { displayName: string };
  balance: number;
  currencyKey: { code: string };
  appuserCurrencyBalance: number;
  accountOwnerAppuserKey: {
    currencyKey: { code: string };
  };
}

interface GetAccountsResponse {
  accounts: Account[];
}

const getAccounts = async () => {
  const { data } = await api.get<GetAccountsResponse>("/v1/account");
  return data;
};

export default function useAccounts<R = GetAccountsResponse>(
  options?: UseQueryOptions<GetAccountsResponse, unknown, R>
) {
  return useQuery(["accounts"], () => getAccounts(), options);
}
