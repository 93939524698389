import { Icon, type IconProps } from "@chakra-ui/react";
import type { IconType } from "react-icons";
import { BiCar, BiMovie } from "react-icons/bi";
import { BsFolder, BsLightningCharge } from "react-icons/bs";
import {
  FaMoneyBill,
  FaMoneyCheckAlt,
  FaRegChartBar,
  FaUmbrellaBeach,
} from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { GiClothes, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { GrBusinessService } from "react-icons/gr";
import { IoIosReturnLeft } from "react-icons/io";
import {
  MdMiscellaneousServices,
  MdMoneyOff,
  MdOutlineFastfood,
} from "react-icons/md";
import { RiFolderUnknowLine, RiGovernmentLine } from "react-icons/ri";
import type { Category } from "../../lib/api/category/useCategories";

export const SuportedCategoryIcons = [
  "GiReceiveMoney",
  "FaMoneyBill",
  "GiPayMoney",
  "FaMoneyCheckAlt",
  "FaRegChartBar",
  "IoIosReturnLeft",
  "BiMovie",
  "BsFolder",
  "MdOutlineFastfood",
  "BiCar",
  "FaUmbrellaBeach",
  "MdMoneyOff",
  "MdMoneyOff",
  "RiGovernmentLine",
  "BsLightningCharge",
  "FiUser",
  "GrBusinessService",
  "GiClothes",
  "RiFolderUnknowLine",
] as const;
export type SupportedCategoryIcons = typeof SuportedCategoryIcons[number];

const getCategoryIconByCategoryName = (
  category: Category["name"]
): IconType => {
  switch (category) {
    case "Income":
      return GiReceiveMoney;
    case "Cash":
      return FaMoneyBill;
    case "Loans":
      return GiPayMoney;
    case "Cheque":
      return FaMoneyCheckAlt;
    case "Savings & Investments":
      return FaRegChartBar;
    case "Returned Payments":
      return IoIosReturnLeft;
    case "Entertainment":
      return BiMovie;
    case "Other":
      return BsFolder;
    case "Food & Drinks":
      return MdOutlineFastfood;
    case "Transportation":
      return BiCar;
    case "Travel":
      return FaUmbrellaBeach;
    case "Bank Fees":
      return MdMoneyOff;
    case "Debt collection":
      return MdMoneyOff;
    case "Government & Non-profit":
      return RiGovernmentLine;
    case "Rent & Utilities":
      return BsLightningCharge;
    case "Personal & Healthcare":
      return FiUser;
    case "Services":
      return MdMiscellaneousServices;
    case "General Merchandise":
      return GiClothes;
    default:
      return RiFolderUnknowLine;
  }
};

const getCategoryIconByIconName = (icon: SupportedCategoryIcons): IconType => {
  switch (icon) {
    case "GiReceiveMoney":
      return GiReceiveMoney;
    case "FaMoneyBill":
      return FaMoneyBill;
    case "GiPayMoney":
      return GiPayMoney;
    case "FaMoneyCheckAlt":
      return FaMoneyCheckAlt;
    case "FaRegChartBar":
      return FaRegChartBar;
    case "IoIosReturnLeft":
      return IoIosReturnLeft;
    case "BiMovie":
      return BiMovie;
    case "BsFolder":
      return BsFolder;
    case "MdOutlineFastfood":
      return MdOutlineFastfood;
    case "BiCar":
      return BiCar;
    case "FaUmbrellaBeach":
      return FaUmbrellaBeach;
    case "MdMoneyOff":
      return MdMoneyOff;
    case "RiGovernmentLine":
      return RiGovernmentLine;
    case "BsLightningCharge":
      return BsLightningCharge;
    case "FiUser":
      return FiUser;
    case "GrBusinessService":
      return GrBusinessService;
    case "GiClothes":
      return GiClothes;
    default:
      return MdMiscellaneousServices;
  }
};

interface CategoryIconProps extends IconProps {
  category?: string;
  icon?: SupportedCategoryIcons;
}

const CategoryIcon: React.FC<CategoryIconProps> = ({
  category,
  icon,
  ...props
}) => {
  if (category) {
    const IconComponent = getCategoryIconByCategoryName(category);
    return <Icon as={IconComponent} {...props} />;
  }
  if (icon) {
    const IconComponent = getCategoryIconByIconName(icon);
    return (
      <Icon
        as={IconComponent}
        width={props.width ?? "20px"}
        height={props.height ?? "20px"}
        stroke="white"
        {...props}
      />
    );
  }
  console.error("Category or icon is required");
  return null;
};

export default CategoryIcon;
