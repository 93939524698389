import { useTheme } from "@emotion/react";
import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine, Serie } from "@nivo/line";

import useNivoTheme from "../../../theme/nivo";

// https://github.com/plouc/nivo/issues/749
// const getIntervalTicks = (data, maxTicks=12) => {
//   if (!(data && data.length)) {
//     console.log('not able to explicitly generate ticks')
//       return 8 // fallback to automatically generated ticks of this number
//   }
//   const {start, end, intervalNum, intervalType} = this.state
//   const intervalCount = Math.floor(moment(end).diff(start, intervalType) / intervalNum)
//   return data[0].data.reduce((accum, point, index) => {
//       const divisor = Math.ceil(intervalCount / maxTicks)
//       if (index % divisor === 0)
//           accum.push(point.x)
//       return accum
//   }, [])
// }

interface IndexLineChartMiniProps {
  data?: Serie[];
  min?: number;
  max?: number;
  symbol?: string;
}

// make sure parent container have a defined height when using responsive component, otherwise
// height will be 0 and no chart will be rendered.
const LineChartMini: React.FC<IndexLineChartMiniProps> = ({
  data,
  min,
  max,
}) => {
  const theme = useTheme();
  const nivoTheme = useNivoTheme({ transparentBackground: true });

  if (!data || !data.length) {
    return null;
  }

  const minValue =
    min || Math.min(...data[0].data.map((item) => item.y as number));
  const maxValue =
    max || Math.max(...data[0].data.map((item) => item.y as number));
  // Add an offset to the value to make sure it is visible on the chart.
  const yAxisTicksOffsetPercentage = 0.06;
  const minYValue = minValue - minValue * yAxisTicksOffsetPercentage;
  const maxYValue = maxValue + maxValue * yAxisTicksOffsetPercentage;

  return (
    <ResponsiveLine
      margin={{ top: 8, right: 0, bottom: 8, left: 0 }}
      data={data}
      colors={Object.values(theme.colors.brand).slice(2)}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        precision: "day",
        // useUTC: false,
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: "linear",
        stacked: false,
        min: minYValue,
        max: maxYValue,
      }}
      axisLeft={null}
      axisBottom={null}
      curve="catmullRom"
      enableArea
      isInteractive={false}
      enableCrosshair={false}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      useMesh={false}
      areaOpacity={0.15}
      areaBaselineValue={minYValue}
      animate
      defs={[
        linearGradientDef("gradientA", [
          { offset: 0, color: "inherit" },
          { offset: 100, color: "inherit", opacity: 0 },
        ]),
      ]}
      fill={[{ match: "*", id: "gradientA" }]}
      theme={nivoTheme}
    />
  );
};

export default LineChartMini;
